// Bootstrap variables overrides
$theme-colors: (
        "primary": #3f51b5,
        "danger": #f44336
);
$body-bg: #fff;
$body-color: #111;
$enable-rounded: false;

// Bootstrap and its default variables
@import "~bootstrap/scss/bootstrap";


body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
